import { render, staticRenderFns } from "./SdsCurrentFilter.vue?vue&type=template&id=3e2b440b&scoped=true"
import script from "./SdsCurrentFilter.vue?vue&type=script&lang=ts&setup=true"
export * from "./SdsCurrentFilter.vue?vue&type=script&lang=ts&setup=true"
import style0 from "./SdsCurrentFilter.vue?vue&type=style&index=0&id=3e2b440b&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e2b440b",
  null
  
)

export default component.exports