
import { readCompanyId } from '@/store/main/getters';
import { commitAddNotification } from '@/store/main/mutations';
import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import { NavigationGuardNext, Route } from 'vue-router';
import { DynamicList } from '@/interfaces/api/general/dynamicList';
import { FormItem } from '@/interfaces/lib/model';
import { Sds, SdsDynamicListResult } from '@/interfaces/model/general/sds';
import { Department } from '@/interfaces/model/general/department';
import { MAX_LIMIT } from '@/lib/constant';
import { getPdfDisplayOptions, getStatusList } from '@/lib/formItem';
import { removeLastPdfExtension } from '@/lib/formatter';
import { casNumberPattern } from '@/lib/regex';
import router from '@/router';
import { store } from '@/store/index';
import {
  dispatchCancelCreateSimpleExportRequest,
  dispatchDeleteDynamicListResult,
  dispatchGetDynamicLists,
  dispatchGetExportCreateSimple,
  dispatchGetExportCreateSimpleV3,
  dispatchPostDynamicListResult,
  dispatchPutDynamicListResult,
  dispatchUpdateCompanySdses,
} from '@/store/main/actionsMain';
import SdsBottomSheet from '@/views/dashboard/bottomSheet/SdsBottomSheet.vue';
import CsvValidationDialog from '@/views/dashboard/bottomSheet/CsvValidationDialog.vue';
import CsvBulkUpdateProcessingDialog from '@/views/dashboard/bottomSheet/CsvBulkUpdateProcessingDialog.vue';
import CompanySdsTable from '@/views/dashboard/CompanySdsTable.vue';
import DownloadProgress from '@/views/dashboard/DownloadProgress.vue';
import SdsSearchForm from '@/views/dashboard/SdsSearchForm.vue';
import SdsFilterForm from '@/views/pages/sdsIndex/SdsFilterForm.vue';
import SdsCurrentFilter from '@/views/pages/sdsIndex/SdsCurrentFilter.vue';
import SdsTableDrawer from '@/views/dashboard/sdsDrawer/SdsTableDrawer.vue';
import {
  useSdsComposable,
  SortState,
  ActualUseSdsComposable,
} from '@/composables/useSds';
import ExportSdses from '@/views/pages/sdsIndex/ExportSdses.vue';
import { Tag, TagResponse } from '@/interfaces/model/general/tag';
import { UpdateCompanySdsesRequestBody } from '@/interfaces/api/general/company';
import { useSelectedItemsComposable } from '@/composables/useSelectedItems';
import { useUserComposable } from '@/composables/useUser';
import { useDepartmentComposable } from '@/composables/useDepartment';
import { usePermissionComposable } from '@/composables/usePermission';
import { useTagComposable } from '@/composables/useTag';
import { NewSds } from '@/lib/constructor/model/general/sds';
import { useDownloadExportSdses } from '@/composables/useDownloadExportSdses';
import { useSdsesExport } from '@/composables/useSdsesExport';
import {
  convertFilterParamsToRequest,
  SdsesFilterParamsKeys,
  SdsesFilterParamsValue,
  useSdsFilter,
} from '@/composables/useSdsFilter';
import { User } from '@/interfaces/vuex/user';
import { defineMessages } from '@/lib/i18n';
import { i18n } from '@/plugins/vue-i18n';
import sharedMessages from '@/lib/shared-messages';
import { readUserLocale } from '@/store/main/getters';
import { ProductCode } from '@/interfaces/model/general/product_code';
import VueI18n from 'vue-i18n';
import {
  fixStr,
  csvItem,
  areSetsEqual,
  updateChangeDateType,
  filterDuplicateTextInArray,
  updateCSVFileDateFormatType,
} from '@/utils/csvValidation';
import { isValidDateFormat } from '@/utils/datetime';
import { departmentNamePattern } from '@/lib/regex';

const locale = computed(() => readUserLocale(store));

const $t = i18n.t.bind(i18n);
const pdfDisplayOptions = getPdfDisplayOptions();
const statusList = getStatusList();

const m = defineMessages({
  updateSuccess: '選択したSDSを更新しました。',
  totalCount: '全 {total}件',
  csvDateLimit: '10000以上のデータをアップロードできません。',
  encodeTypeInvalid: 'フィールド内の文字エンコードが無効です。',
  sdsIdIsNotNull: 'SDS IDは空にできません。',
  sdsIdIsNotExist: 'SDS IDに該当するSDSが存在しません。',
  sdsNameIsNotNull: 'SDS名は空にできません。',
  sdsFileIsNotPdf: 'ファイルがpdfではありません。',
  sdsDepartmentCharatorIssue:
    '部署名には日本語、アルファベット、数字などを使用してください（文頭には特殊文字は使用できません）。',
  sdsDepartmentNonExist: '存在しない部署名が含まれています。',
  sdsDepartmentNonEdit: '管理者以外は、他部署のSDSを編集することはできません。',
  sdsTagNameIsNotOver45: 'タグ名は45文字以内で入力してください。',
  sdsTagNameInvalid: '存在しないタグが含まれています。',
  sdsRiskAssessDateType:
    'リスクアセスメント実施日は YYYY-MM-DD 形式である必要があります。',
  sdsSupplierConfirmationDateType:
    'サプライヤーへの確認日は YYYY-MM-DD 形式である必要があります。',
  sdsDepartmentDuplicated: '同一の部署名が複数入力されています。',
  sdsTagDuplicated: '同一のタグ名が複数入力されています。',
  sdsProductCodeDuplicated: '同一の製品コード名が複数入力されています。',
  sdsDateNonChanged: 'csvデータには変更はありません。',
});

interface ErrorMsg {
  rowIndex: number;
  message: string;
}

interface ValidationResult {
  errorCount: number;
  sdsID: ErrorMsg[];
  pdfName: ErrorMsg[];
  riskAssessDate: ErrorMsg[];
  supplierConfirmationDate: ErrorMsg[];
  tag: ErrorMsg[];
  department: ErrorMsg[];
  productCode: ErrorMsg[];
}

export default defineComponent({
  components: {
    SdsBottomSheet,
    CsvValidationDialog,
    CsvBulkUpdateProcessingDialog,
    CompanySdsTable,
    SdsTableDrawer,
    SdsSearchForm,
    SdsFilterForm,
    SdsCurrentFilter,
    DownloadProgress,
    ExportSdses,
  },
  beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext): void {
    if (this.isEditing) {
      const isAllowed: boolean = window.confirm(
        $t(sharedMessages?.confirmLeave)
      );
      if (isAllowed) {
        next();
      }
    } else {
      next();
    }
  },
  setup() {
    const showSdsDetailView = ref<boolean>(false);
    const pdfName = ref<string>('');
    const keywordList = ref<Array<string>>([]);
    const productFormItems = ref<Array<{ text: string; value: number }>>([]);
    const materialFormItems = ref<Array<FormItem>>([]);
    const casFormItems = ref<Array<FormItem>>([]);
    const page = ref<number>(1);
    const limit = ref<number>(50);
    const isEditing = ref<boolean>(false);
    const isLoading = ref<boolean>(false);
    const isDownloadable = ref<boolean>(false);
    const isUploadable = ref<boolean>(false);
    const canExportCreateSimpleCount = ref<number>(0);
    const canExportCreateSimpleCountV3 = ref<number>(0);
    const dialogShow = ref<boolean>(false);

    const isChange = ref<number>(0);
    const isSdsFilterOpen = ref(false);
    const dynamicLists = ref<Array<DynamicList>>([]);
    const assigneeUsers = ref<User[]>([]);
    const activeSds = ref<Sds>(NewSds);
    const currentIndex = ref(0);

    const isVisibleCSVValidationDialog = ref<boolean>(false);
    const isVisibleCsvBulkUpdateProcessingDialog = ref<boolean>(false);
    const totalChangedNumber = ref<number>(0);
    const nameChangedNumber = ref<number>(0);
    const riskAssessDateChangedNumber = ref<number>(0);
    const supplierConfirmationDateChangedNumber = ref<number>(0);
    const departmentChangedNumber = ref<number>(0);
    const tagChangedNumber = ref<number>(0);
    const productCodeChangedNumber = ref<number>(0);

    const isDisableSdsBulkCsvDialog = ref<boolean>(true);
    const changedCsvData = ref<Array<csvItem>>([]);
    const validationErrorMsgs = ref<ValidationResult>({
      errorCount: 0,
      sdsID: [],
      pdfName: [],
      riskAssessDate: [],
      supplierConfirmationDate: [],
      tag: [],
      department: [],
      productCode: [],
    });

    const {
      sds,
      sdses,
      total,
      pdfBlob,
      wholeSds,
      fetchCompanySds,
      fetchCompanySdses,
      fetchSdsPdf,
      fetchCompanySdsesCount,
      fetchCompanySdsesCountWithCondition,
      updateCompanySds,
      deleteCompanySdses,
      downloadSelectedSdsesCSV,
      uploadSelectedSdsesCSV,
    } = useSdsComposable();
    const { users, getCompanyUsers } = useUserComposable();
    const { getCompanyDepartments, companyDepartments } =
      useDepartmentComposable();
    const { tags, getCompanyTag } = useTagComposable();
    const [
      selectedSdses,
      isSelectedAllSdses,
      isSelectedIndeterminateSdses,
      isSelectedWholeSdses,
      toggleSelectedSds,
      toggleAllSelectedSdses,
      toggleWholeSelectedSdses,
      resetSelectedSdses,
    ] = useSelectedItemsComposable<Sds>(
      sdses.value,
      (sds1, sds2) => sds1.uuid === sds2.uuid
    );
    const { hasPermission, PERMISSIONS: P } = usePermissionComposable();
    const {
      currentFilterParams,
      updateFormFilterParam,
      applyFormFilterParams,
      formFilterParams,
      statusOptions,
      userOptions,
      departmentOptions,
      dynamicListOptions,
      tagOptions,
      hasFilterParams,
    } = useSdsFilter(users, tags, companyDepartments, dynamicLists);

    const { downloadExportSdses } = useDownloadExportSdses();
    const { exportOperations, fetchCompanyExportOperations } = useSdsesExport();

    const sortState = ref<SortState>({
      name: 'none',
      revised_at: 'none',
      created_at: 'none',
      assessed_at: 'none',
      supplier_confirmed_at: 'none',
    });

    const handleClickProductName = (name: string): void => {
      updateFormFilterParam('productName', name);

      applyFilterParams();
    };

    const handleClickManufacturerName = (name: string): void => {
      updateFormFilterParam('manufacturerName', name);

      applyFilterParams();
    };

    const handleClickProductCode = (code: string): void => {
      updateFormFilterParam('productCode', code);

      applyFilterParams();
    };

    const handleClickTag = (tag: TagResponse): void => {
      const newParams = Array.from(
        new Set([...formFilterParams.value.tagIds, tag.id])
      );
      updateFormFilterParam('tagIds', newParams);

      applyFilterParams();
    };

    const handleClickDepartment = (department: Department): void => {
      const newParams = Array.from(
        new Set([...formFilterParams.value.departmentUuids, department.uuid])
      );
      updateFormFilterParam('departmentUuids', newParams);

      applyFilterParams();
    };

    const handleClickStatus = (statusGroupId: string): void => {
      const newParams = Array.from(
        new Set([...formFilterParams.value.statusGroupId, statusGroupId])
      );
      updateFormFilterParam('statusGroupId', newParams);

      applyFilterParams();
    };

    const removeFilterParams = (
      key: SdsesFilterParamsKeys,
      value: SdsesFilterParamsValue
    ) => {
      updateFormFilterParam(key, value);

      applyFilterParams();
    };

    const handleExportSubmit = () => {
      selectedSdses.value = [];
    };

    const toggleSort = (column: string): void => {
      if (sortState.value[column] === 'none') {
        sortState.value[column] = 'asc';
      } else if (sortState.value[column] === 'asc') {
        sortState.value[column] = 'desc';
      } else {
        sortState.value[column] = 'none';
      }
      Object.keys(sortState.value).forEach((key) => {
        if (key !== column) {
          sortState.value[key] = 'none';
        }
      });
      applyFilterParams();
    };

    const cancelExportCreateSimple = (): void => {
      dispatchCancelCreateSimpleExportRequest(store);
      dialogShow.value = false;
    };

    watch(showSdsDetailView, (next) => {
      if (next === false) {
        activeSds.value = NewSds;
      }
    });

    const hasPrevSds = computed((): boolean => {
      return currentIndex.value > 0;
    });

    const hasNextSds = computed((): boolean => {
      return currentIndex.value < sdses.value.length - 1;
    });

    const showPrevSds = (): void => {
      const index = currentIndex.value - 1;

      showSdsDetail(sdses.value[index], index);
    };

    const showNextSds = async (): Promise<void> => {
      const index = currentIndex.value + 1;

      showSdsDetail(sdses.value[index], index);
    };

    const resetPagenation = (): void => {
      page.value = 1;
    };

    const showSdsDetail = async (item: Sds, index: number): Promise<void> => {
      await doFetchCompanySds(item.uuid);
      await doFetchPdf(item.uuid);

      showSdsDetailView.value = true;
      activeSds.value = { ...sds.value };
      isLoading.value = true;

      canExportCreateSimpleCount.value = 0;
      canExportCreateSimpleCountV3.value = 0;

      currentIndex.value = index;

      productFormItems.value.splice(0);
      productFormItems.value.push(
        ...item.sds_products.map((x, idx) => {
          return {
            text: x.name,
            value: idx,
          };
        })
      );
      materialFormItems.value.splice(0);
      casFormItems.value.splice(0);
      for (const product of sds.value.sds_products) {
        // material_list内のcas_listをcasNumberPatternでマッチングし、1つ以上マッチしたらcanExportCreateSimpleCountV3をインクリメント
        const casList = product.sds_section3.material_list
          .map((x) => x.cas_list)
          .flat()
          .filter((x) => x.cas_number.match(casNumberPattern));
        if (casList.length > 0) {
          canExportCreateSimpleCountV3.value++;
        }

        for (const material of product.sds_section3.material_list) {
          materialFormItems.value.push({
            text: material.material_name,
            value: material.material_name,
          });
          for (const cas of material.cas_list) {
            casFormItems.value.push({
              text: cas.cas_number,
              value: cas.cas_number,
            });
            if (cas.cas_number.match(casNumberPattern)) {
              canExportCreateSimpleCount.value++;
            }
          }
        }
      }

      isLoading.value = false;
    };

    const doFetchPdf = async (sdsId: string): Promise<void> => {
      fetchSdsPdf({
        companyId: readCompanyId(store),
        sdsId: sdsId,
      });
    };

    const doFetchCompanySds = async (sdsId: string): Promise<void> => {
      await fetchCompanySds({
        sdsId: sdsId,
      });
    };

    const doDeleteCompanySdses = async (): Promise<void> => {
      isLoading.value = true;
      try {
        await deleteCompanySdses({
          companyId: readCompanyId(store),
          data: {
            ...convertFilterParamsToRequest(currentFilterParams.value),
            sds_uuid_list: isSelectedWholeSdses.value
              ? []
              : selectedSdses.value.map((sds) => sds.uuid),
            is_selected_whole_sdses: isSelectedWholeSdses.value,
            keyword_list: keywordList.value,
          },
        });

        resetStatuses();
        await fetchSdses();
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
      }
    };

    const doUpdateCompanySds = async (
      sdsId: string,
      data: Sds,
      tags: TagResponse[],
      departments: Department[]
    ): Promise<void> => {
      await updateCompanySds(
        readCompanyId(store),
        sdsId,
        data,
        departments,
        tags,
        store.state.main.userProfile
      );
      await doFetchCompanySds(data.uuid);
      await fetchSdses();
    };

    const fetchDynamicLists = async () => {
      dynamicLists.value.splice(0);

      const res = await dispatchGetDynamicLists(store, {
        page: page.value - 1,
        limit: MAX_LIMIT,
        showAll: false,
      });
      if (res && res.data) {
        dynamicLists.value = [...res.data.items];
      }
    };

    const cancelEdit = async (sdsId: string): Promise<void> => {
      isLoading.value = true;
      isChange.value++;
      try {
        await doFetchCompanySds(sdsId);

        productFormItems.value.splice(0);
        productFormItems.value.push(
          ...sds.value.sds_products.map((x, idx) => {
            return {
              text: x.name,
              value: idx,
            };
          })
        );
        materialFormItems.value.splice(0);
        casFormItems.value.splice(0);
        for (const product of sds.value.sds_products) {
          for (const material of product.sds_section3.material_list) {
            materialFormItems.value.push({
              text: material.material_name,
              value: material.material_name,
            });
            for (const cas of material.cas_list) {
              casFormItems.value.push({
                text: cas.cas_number,
                value: cas.cas_number,
              });
            }
          }
        }

        // activeSds を直接編集しているため、キャンセル時には元の値に戻す
        activeSds.value = { ...sds.value };
      } finally {
        isChange.value++;
        isLoading.value = false;
      }
    };

    const confirmSave = (event: {
      preventDefault: () => void;
      returnValue: VueI18n.TranslateResult;
    }): void => {
      if (isEditing.value) {
        event.preventDefault();
        event.returnValue = $t(sharedMessages.confirmLeave);
      }
    };

    const exportCreateSimple = async (sdsId: string): Promise<void> => {
      try {
        dialogShow.value = true;

        const res = await dispatchGetExportCreateSimple(store, {
          sdsId: sdsId,
        });
        if (res) {
          const blob = new Blob([res.data], {
            type: 'application/zip',
          });
          const exportSds = sdses.value.find((x) => x.uuid === sdsId);
          //for type guard
          if (!exportSds) {
            return;
          }
          const link = document.createElement('a');

          const fileName = removeLastPdfExtension(exportSds.name);
          const now = new Date();

          // 日本のタイムゾーンに変換
          const japanTime = new Intl.DateTimeFormat(locale.value, {
            timeZone: 'Asia/Tokyo',
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
          }).format(now);

          // YYYY-MM-DD HH:mm:ss 形式に整形
          const timestampString = japanTime
            .replace(/\//g, '-')
            .replace(/\s/, ' ')
            .replace(/:/g, '');

          link.href = URL.createObjectURL(blob);
          link.download = `${fileName}_${timestampString}_CREATE SIMPLE v2.5.1.zip`;
          link.click();
          URL.revokeObjectURL(link.href);
        }
      } catch (error) {
        console.log(error);
      } finally {
        dialogShow.value = false;
      }
    };

    const exportCreateSimpleV3 = async (sdsId: string): Promise<void> => {
      try {
        dialogShow.value = true;

        const res = await dispatchGetExportCreateSimpleV3(store, {
          sdsId: sdsId,
        });
        if (res) {
          const blob = new Blob([res.data], {
            type: 'application/zip',
          });
          const exportSds = sdses.value.find((x) => x.uuid === sdsId);
          //for type guard
          if (!exportSds) {
            return;
          }

          const link = document.createElement('a');

          const fileName = removeLastPdfExtension(exportSds.name);
          const now = new Date();

          // 日本のタイムゾーンに変換
          const japanTime = new Intl.DateTimeFormat(locale.value, {
            timeZone: 'Asia/Tokyo',
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
          }).format(now);

          // YYYY-MM-DD HH:mm:ss 形式に整形
          const timestampString = japanTime
            .replace(/\//g, '-')
            .replace(/\s/, ' ')
            .replace(/:/g, '');

          link.href = URL.createObjectURL(blob);
          link.download = `${fileName}_${timestampString}_CREATE SIMPLE v3.0.4.zip`;
          link.click();
          URL.revokeObjectURL(link.href);
        }
      } catch (error) {
        console.log(error);
      } finally {
        dialogShow.value = false;
      }
    };

    const editDynamicListResult = async (
      sdsId: string,
      dynamicListId: number,
      dynamicListResult: SdsDynamicListResult
    ): Promise<void> => {
      try {
        const res = await dispatchPutDynamicListResult(store, {
          sdsId: sdsId,
          dynamicListId: dynamicListId,
          dynamicListResult: dynamicListResult,
        });

        if (res) {
          await doFetchCompanySds(sdsId);
        }
      } catch (error) {
        console.log(error);
      }
    };

    const addDynamicListResult = async (
      sdsId: string,
      dynamicListId: number
    ): Promise<void> => {
      try {
        const res = await dispatchPostDynamicListResult(store, {
          sdsId: sdsId,
          dynamicListId: dynamicListId,
        });
        if (res) {
          await doFetchCompanySds(sdsId);
        }
      } catch (error) {
        console.log(error);
      }
    };

    const deleteDynamicListResult = async (
      sdsId: string,
      dynamicListId: number
    ): Promise<void> => {
      try {
        const res = await dispatchDeleteDynamicListResult(store, {
          sdsId: sdsId,
          dynamicListId: dynamicListId,
        });
        if (res) {
          await doFetchCompanySds(sdsId);
        }
      } catch (error) {
        console.log(error);
      }
    };

    const toggleSdsFilter = (): void => {
      isSdsFilterOpen.value = !isSdsFilterOpen.value;
    };

    const updateSelectedSdses = async (
      form: Omit<UpdateCompanySdsesRequestBody, 'sds_uuid_list'>
    ) => {
      isLoading.value = true;
      try {
        const res = await dispatchUpdateCompanySdses(store, {
          companyId: readCompanyId(store),
          data: {
            ...form,
            ...convertFilterParamsToRequest(currentFilterParams.value),
            sds_uuid_list: isSelectedWholeSdses.value
              ? []
              : selectedSdses.value.map((sds) => sds.uuid),
            is_selected_whole_sdses: isSelectedWholeSdses.value,
            keyword_list: keywordList.value,
          },
        });
        if (res && res.data) {
          commitAddNotification(store, {
            messageKey: `${$t(m.updateSuccess)}`,
            color: 'success',
          });
        }
        await fetchSdses();
        selectedSdses.value = [];
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
      }
    };
    const doDownloadSelectedSdsesCSV = async (): Promise<void> => {
      isDownloadable.value = true;
      try {
        const response = await downloadSelectedSdsesCSV({
          companyId: store.state.main.company.uuid,
          data: {
            ...convertFilterParamsToRequest(currentFilterParams.value),
            sds_uuid_list: isSelectedWholeSdses.value
              ? []
              : selectedSdses.value.map((sds) => sds.uuid),
            is_selected_whole_sdses: isSelectedWholeSdses.value,
            keyword_list: keywordList.value,
            limit: limit,
            page: page,
          },
        });
        const encoder = new TextEncoder();
        const data = encoder.encode('\uFEFF' + response);

        const blob = new Blob([data], {
          type: 'text/csv;charset=shift-jis;',
        });

        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'sds-data.csv';
        link.click();
        URL.revokeObjectURL(link.href);
      } catch (error) {
        commitAddNotification(store, {
          messageKey: `${error?.response?.data?.detail}`,
          color: 'error',
        });
      } finally {
        isDownloadable.value = false;
      }
    };

    const chunkArray = (array: csvItem[], chunkSize: number) => {
      const result = [];
      for (let i = 0; i < array.length; i += chunkSize) {
        result.push(array.slice(i, i + chunkSize));
      }
      return result;
    };
    const doUploadSelectedSdsesCSV = async (
      isApproved: boolean,
      hideParentModal: boolean
    ): Promise<void> => {
      isDisableSdsBulkCsvDialog.value = hideParentModal;
      let response = null;
      if (changedCsvData.value.length) {
        try {
          isVisibleCsvBulkUpdateProcessingDialog.value = true;
          const dateTypeChangedCsvData = updateCSVFileDateFormatType(
            changedCsvData.value
          );
          if (dateTypeChangedCsvData.length > 500) {
            const chunkedSdsList = chunkArray(dateTypeChangedCsvData, 500);
            for (let chunk of chunkedSdsList) {
              response = await uploadSelectedSdsesCSV({
                companyId: store.state.main.company.uuid,
                data: {
                  ...convertFilterParamsToRequest(currentFilterParams.value),
                  sds_uuid_list: chunk.map((sds) => sds.sdsID),
                  is_selected_whole_sdses: false,
                  keyword_list: keywordList.value,
                  sds_data: JSON.stringify(chunk),
                  limit: chunk.length,
                  page: page.value - 1,
                  isApproved,
                },
              });
            }
          } else {
            response = await uploadSelectedSdsesCSV({
              companyId: store.state.main.company.uuid,
              data: {
                ...convertFilterParamsToRequest(currentFilterParams.value),
                sds_uuid_list: dateTypeChangedCsvData.map((sds) => sds.sdsID),
                is_selected_whole_sdses: false,
                keyword_list: keywordList.value,
                sds_data: JSON.stringify(dateTypeChangedCsvData),
                limit: isSelectedWholeSdses.value
                  ? limit.value
                  : selectedSdses.value.length,
                page: page.value - 1,
                isApproved,
              },
            });
          }
          if (response) {
            commitAddNotification(store, {
              messageKey: '選択したSDSを更新しました。',
              color: 'success',
            });
            selectedSdses.value = [];
          }
        } catch (error) {
          isLoading.value = false;
          commitAddNotification(store, {
            messageKey: 'SDS の更新に失敗しました。',
            color: 'error ',
          });
        } finally {
          isLoading.value = false;
        }
      } else {
        commitAddNotification(store, {
          messageKey: `${$t(m.sdsDateNonChanged)}`,
          color: 'warning ',
        });
      }
    };

    const csvFileValidate = async (
      csvDataArray: csvItem[],
      hideParentModal: boolean
    ) => {
      changedCsvData.value = [];
      const validationMsgs: ValidationResult = {
        errorCount: 0,
        sdsID: [],
        pdfName: [],
        riskAssessDate: [],
        supplierConfirmationDate: [],
        tag: [],
        department: [],
        productCode: [],
      };
      validationErrorMsgs.value = validationMsgs;

      if (csvDataArray.length > 10000) {
        isUploadable.value = false;
        commitAddNotification(store, {
          messageKey: `${$t(m.csvDateLimit)}`,
          color: 'error',
        });
        return false;
      }
      isUploadable.value = true;
      totalChangedNumber.value = csvDataArray.length;
      nameChangedNumber.value = 0;
      riskAssessDateChangedNumber.value = 0;
      supplierConfirmationDateChangedNumber.value = 0;
      departmentChangedNumber.value = 0;
      tagChangedNumber.value = 0;
      productCodeChangedNumber.value = 0;

      if (isSelectedWholeSdses.value) {
        const requestFilterParams = convertFilterParamsToRequest(
          currentFilterParams.value
        );
        try {
          await fetchCompanySdses({
            companyId: readCompanyId(store),
            data: {
              ...requestFilterParams,
              sortState: sortState.value,
              keyword_list: keywordList.value,
              limit: 10000,
            },
          });
        } catch (error) {
          isUploadable.value = false;
          commitAddNotification(store, {
            messageKey: 'Fetch SDS failed',
            color: 'error',
          });
          return false;
        }
      }

      let rowIndex = 1;
      for (const csvData of csvDataArray) {
        let isAdded = false;
        if (!('sdsID' in csvData)) {
          isUploadable.value = false;
          commitAddNotification(store, {
            messageKey: `${$t(m.encodeTypeInvalid)}`,
            color: 'error',
          });
          return false;
        }

        if (!csvData?.sdsID) {
          const validationMsg: ErrorMsg = {
            rowIndex: rowIndex + 1,
            message: `${$t(m.sdsIdIsNotNull)}`,
          };
          validationMsgs.errorCount += 1;
          validationMsgs.sdsID.push(validationMsg);
        }

        const storedSds = isSelectedWholeSdses.value
          ? wholeSds.value.filter((sds: Sds) => sds.uuid === csvData?.sdsID)
          : sdses.value.filter((sds: Sds) => sds.uuid === csvData?.sdsID);

        if (!storedSds.length && csvData?.sdsID) {
          const validationMsg: ErrorMsg = {
            rowIndex: rowIndex + 1,
            message: `${$t(m.sdsIdIsNotExist)}`,
          };
          validationMsgs.errorCount += 1;
          validationMsgs.sdsID.push(validationMsg);
        }

        const pdfName = csvData?.pdfName ?? '';
        const riskAssessDate =
          csvData?.riskAssessDate === '' ? null : csvData?.riskAssessDate;
        const supplierConfirmationDate =
          csvData?.supplierConfirmationDate === ''
            ? null
            : csvData?.supplierConfirmationDate;
        const csvTags =
          csvData?.tag === ''
            ? []
            : csvData?.tag?.normalize('NFC').trim().split(',');
        const csvDepartments =
          csvData?.department === ''
            ? []
            : csvData?.department?.normalize('NFC').trim().split(',');
        const csvProdutCodes =
          csvData?.productCode === ''
            ? []
            : csvData?.productCode?.normalize('NFC').trim().split(',');

        // validation sdsName
        if (!pdfName || pdfName.trim() === '') {
          const validationMsg: ErrorMsg = {
            rowIndex: rowIndex + 1,
            message: `${$t(m.sdsNameIsNotNull)}`,
          };
          validationMsgs.errorCount += 1;
          validationMsgs.pdfName.push(validationMsg);
        }

        if (pdfName && pdfName.split('.').pop()?.toLowerCase() !== 'pdf') {
          const validationMsg: ErrorMsg = {
            rowIndex: rowIndex + 1,
            message: `${$t(m.sdsFileIsNotPdf)}`,
          };
          validationMsgs.errorCount += 1;
          validationMsgs.pdfName.push(validationMsg);
        }

        if (storedSds[0] && fixStr(pdfName) !== fixStr(storedSds[0]['name'])) {
          nameChangedNumber.value += 1;
          !isAdded && changedCsvData.value.push(csvData);
          isAdded = true;
        }

        // riskAssessDate validation
        if (riskAssessDate && !isValidDateFormat(riskAssessDate)) {
          const validationMsg: ErrorMsg = {
            rowIndex: rowIndex + 1,
            message: `${$t(m.sdsRiskAssessDateType)}`,
          };
          validationMsgs.errorCount += 1;
          validationMsgs.riskAssessDate.push(validationMsg);
        }

        if (
          storedSds[0] &&
          updateChangeDateType(riskAssessDate) !==
            updateChangeDateType(storedSds[0]['assessed_at'])
        ) {
          riskAssessDateChangedNumber.value += 1;
          !isAdded && changedCsvData.value.push(csvData);
          isAdded = true;
        }

        // supplierConfirmationDate validation
        if (
          supplierConfirmationDate &&
          !isValidDateFormat(supplierConfirmationDate)
        ) {
          const validationMsg: ErrorMsg = {
            rowIndex: rowIndex + 1,
            message: `${$t(m.sdsSupplierConfirmationDateType)}`,
          };
          validationMsgs.errorCount += 1;
          validationMsgs.supplierConfirmationDate.push(validationMsg);
        }

        if (
          storedSds[0] &&
          updateChangeDateType(supplierConfirmationDate) !==
            updateChangeDateType(storedSds[0]['supplier_confirmed_at'])
        ) {
          supplierConfirmationDateChangedNumber.value += 1;
          !isAdded && changedCsvData.value.push(csvData);
          isAdded = true;
        }

        // department validation
        if (csvDepartments?.length) {
          const { seen, duplicates } =
            filterDuplicateTextInArray(csvDepartments);
          if (duplicates.length) {
            for (const department of duplicates) {
              const validationMsg: ErrorMsg = {
                rowIndex: rowIndex + 1,
                message: `[${department}] ${$t(m.sdsDepartmentDuplicated)}`,
              };
              validationMsgs.errorCount += 1;
              validationMsgs.department.push(validationMsg);
            }
          }
          for (const department of seen) {
            if (department) {
              if (!department.match(departmentNamePattern)) {
                const validationMsg: ErrorMsg = {
                  rowIndex: rowIndex + 1,
                  message: `[${department}] ${$t(
                    m.sdsDepartmentCharatorIssue
                  )}`,
                };
                validationMsgs.errorCount += 1;
                validationMsgs.department.push(validationMsg);
              }

              const isDepartmetns = companyDepartments.value.filter(
                (companyDepartment) =>
                  companyDepartment.name.normalize('NFC').trim() ===
                  department.normalize('NFC').trim()
              );
              if (!isDepartmetns.length) {
                const validationMsg: ErrorMsg = {
                  rowIndex: rowIndex + 1,
                  message: `[${department}] ${$t(m.sdsDepartmentNonExist)}`,
                };
                validationMsgs.errorCount += 1;
                validationMsgs.department.push(validationMsg);
              }
            }
          }
        }

        if (
          hasPermission(P.SDS_EDIT_OWN_DEPARTMENT) &&
          !hasPermission(P.SDS_EDIT_OTHER_DEPARTMENT)
        ) {
          const isValidDepartmentMsg = validationMsgs.department.filter(
            (msg) => msg.rowIndex === rowIndex + 1
          );
          if (!isValidDepartmentMsg.length) {
            const csvDepartmentsSet = new Set(csvDepartments);
            const userDepartments = store.state.main.userProfile.departments;

            const isBelongIntoUserDepartments = userDepartments.filter((dept) =>
              csvDepartmentsSet.has(dept.name)
            );
            if (!isBelongIntoUserDepartments.length) {
              const validationMsg: ErrorMsg = {
                rowIndex: rowIndex + 1,
                message: `${$t(m.sdsDepartmentNonEdit)}`,
              };
              validationMsgs.errorCount += 1;
              validationMsgs.department.push(validationMsg);
            }
          }
        }

        const currentDepartmentsNameArray = storedSds[0]
          ? storedSds[0].departments.map((currDepartment: Department) =>
              currDepartment.name.normalize('NFC').trim()
            )
          : [];
        if (
          storedSds[0] &&
          !areSetsEqual(
            new Set(csvDepartments),
            new Set(currentDepartmentsNameArray)
          )
        ) {
          departmentChangedNumber.value += 1;
          !isAdded && changedCsvData.value.push(csvData);
          isAdded = true;
        }

        // tag validation
        if (csvTags?.length) {
          const { seen, duplicates } = filterDuplicateTextInArray(csvTags);
          if (duplicates.length) {
            for (const tag of duplicates) {
              const validationMsg: ErrorMsg = {
                rowIndex: rowIndex + 1,
                message: `[${tag}] ${$t(m.sdsTagDuplicated)}`,
              };
              validationMsgs.errorCount += 1;
              validationMsgs.tag.push(validationMsg);
            }
          }
          for (const tag of seen) {
            if (tag) {
              if (tag.length > 45) {
                const validationMsg: ErrorMsg = {
                  rowIndex: rowIndex + 1,
                  message: `[${tag}] ${$t(m.sdsTagNameIsNotOver45)}`,
                };
                validationMsgs.errorCount += 1;
                validationMsgs.tag.push(validationMsg);
              }
              const isValidTagMsg = validationMsgs.tag.filter(
                (msg) => msg.rowIndex === rowIndex + 1
              );
              if (!isValidTagMsg.length) {
                const isTags = tags.value.filter(
                  (companyTag) =>
                    companyTag.name.normalize('NFC').trim() ===
                    tag.normalize('NFC').trim()
                );
                if (!isTags.length) {
                  const validationMsg: ErrorMsg = {
                    rowIndex: rowIndex + 1,
                    message: `[${tag}] ${$t(m.sdsTagNameInvalid)}`,
                  };
                  validationMsgs.errorCount += 1;
                  validationMsgs.tag.push(validationMsg);
                }
              }
            }
          }
        }

        const currentTagsNameArray = storedSds[0]
          ? storedSds[0].tags.map((currTag: Tag) =>
              currTag.name.normalize('NFC').trim()
            )
          : [];
        if (
          storedSds[0] &&
          !areSetsEqual(new Set(csvTags), new Set(currentTagsNameArray))
        ) {
          tagChangedNumber.value += 1;
          !isAdded && changedCsvData.value.push(csvData);
          isAdded = true;
        }

        // product code validation
        if (csvProdutCodes?.length) {
          const { duplicates } = filterDuplicateTextInArray(csvProdutCodes);
          if (duplicates.length) {
            for (const productCode of duplicates) {
              const validationMsg: ErrorMsg = {
                rowIndex: rowIndex + 1,
                message: `[${productCode}] ${$t(m.sdsProductCodeDuplicated)}`,
              };
              validationMsgs.errorCount += 1;
              validationMsgs.productCode.push(validationMsg);
            }
          }
        }

        const currentProductCodesNameArray = storedSds[0]
          ? storedSds[0].product_codes.map((currProdCode: ProductCode) =>
              currProdCode.product_code.normalize('NFC').trim()
            )
          : [];
        if (
          storedSds[0] &&
          !areSetsEqual(
            new Set(csvProdutCodes),
            new Set(currentProductCodesNameArray)
          )
        ) {
          productCodeChangedNumber.value += 1;
          !isAdded && changedCsvData.value.push(csvData);
          isAdded = true;
        }

        rowIndex++;
      }

      isUploadable.value = false;
      isDisableSdsBulkCsvDialog.value = hideParentModal;
      isVisibleCSVValidationDialog.value = true;
      validationErrorMsgs.value = validationMsgs;
    };

    const closeBulkCsvUploadForm = () => {
      isDisableSdsBulkCsvDialog.value = false;
    };

    const resetStatuses = () => {
      resetSelectedSdses();
      resetPagenation();
    };

    const handleChangeKeywords = async () => {
      resetStatuses();
      await fetchSdses();
    };

    const fetchSdses = async () => {
      const requestFilterParams = convertFilterParamsToRequest(
        currentFilterParams.value
      );

      await Promise.all([
        //TODO: useSdsのanyが取れたら外す
        (
          fetchCompanySdsesCount as ActualUseSdsComposable['fetchCompanySdsesCount']
        )({
          companyId: readCompanyId(store),
          data: {
            ...requestFilterParams,
            keyword_list: keywordList.value,
          },
        }),

        (fetchCompanySdses as ActualUseSdsComposable['fetchCompanySdses'])({
          companyId: readCompanyId(store),
          data: {
            ...requestFilterParams,
            limit: limit.value,
            page: page.value - 1,
            sortState: sortState.value,
            keyword_list: keywordList.value,
          },
        }),
        (
          fetchCompanySdsesCountWithCondition as ActualUseSdsComposable['fetchCompanySdsesCountWithCondition']
        )({
          companyId: readCompanyId(store),
          data: {
            ...requestFilterParams,
            keyword_list: keywordList.value,
          },
        }),
      ]);
    };

    const applyFilterParams = async () => {
      applyFormFilterParams();
      resetStatuses();
      isSdsFilterOpen.value = false;
      isLoading.value = true;
      try {
        await fetchSdses();
      } finally {
        isLoading.value = false;
      }
    };

    const resetFilters = (): void => {
      updateFormFilterParam('tagIds', []);
      updateFormFilterParam('statusGroupId', []);
      updateFormFilterParam('assigneeUsers', []);
      updateFormFilterParam('departmentUuids', []);
      updateFormFilterParam('dynamicListIds', []);
      updateFormFilterParam('sdsName', '');
      updateFormFilterParam('productName', '');
      updateFormFilterParam('manufacturerName', '');
      updateFormFilterParam('productCode', '');
      updateFormFilterParam('revisedAtStartDate', '');
      updateFormFilterParam('revisedAtEndDate', '');
      updateFormFilterParam('createdAtStartDate', '');
      updateFormFilterParam('createdAtEndDate', '');
      updateFormFilterParam('isLatestOnly', false);
      applyFilterParams();
    };

    const filterSdsesAndShowSdsDetail = async (uuid: string): Promise<void> => {
      // preload sds detail to add the name to keywordList
      await doFetchCompanySds(uuid);
      keywordList.value.push(sds.value.name);
      currentIndex.value = sdses.value.findIndex((x) => x.uuid === uuid);

      // fetch a filtered sdses list after sds.value.name is added to keywordList filter
      await fetchSdses();

      // Open SDS detail modal when the associated SDS is found in sdses
      const item = sdses.value.find((x) => x.uuid === uuid);
      if (item) {
        showSdsDetail(item, sdses.value.indexOf(item));
      }
    };

    onMounted(async (): Promise<void> => {
      //export queryがあればダウンロード処理を行う
      await downloadExportSdses();

      window.onbeforeunload = confirmSave;

      isLoading.value = true;

      try {
        await getCompanyDepartments();
        await fetchDynamicLists();
        await getCompanyUsers();
        await getCompanyTag();
        await fetchCompanyExportOperations(readCompanyId(store));

        const uuid = router.currentRoute.query.uuid;
        if (uuid && typeof uuid === 'string') {
          await filterSdsesAndShowSdsDetail(uuid);
        } else {
          await fetchSdses();
        }
      } finally {
        isLoading.value = false;
      }
    });

    return {
      resetFilters,
      sortState,
      toggleSort,
      keywordList,
      pdfBlob,
      pdfName,
      limit,
      total,
      page,
      statusList,
      pdfDisplayOptions,
      sds,
      currentFilterParams,
      formFilterParams,
      hasFilterParams,
      statusOptions,
      userOptions,
      tagOptions,
      departmentOptions,
      dynamicListOptions,
      updateFormFilterParam,
      removeFilterParams,
      handleExportSubmit,
      applyFilterParams,
      handleChangeKeywords,
      sdses,
      wholeSds,
      selectedSdses,
      isSelectedAllSdses,
      isSelectedIndeterminateSdses,
      isSelectedWholeSdses,
      toggleSelectedSds,
      toggleAllSelectedSdses,
      toggleWholeSelectedSdses,
      resetSelectedSdses,
      isLoading,
      isEditing,
      isUploadable,
      isDownloadable,
      showSdsDetailView,
      isVisibleCSVValidationDialog,
      isVisibleCsvBulkUpdateProcessingDialog,
      totalChangedNumber,
      nameChangedNumber,
      riskAssessDateChangedNumber,
      supplierConfirmationDateChangedNumber,
      departmentChangedNumber,
      tagChangedNumber,
      productCodeChangedNumber,
      validationErrorMsgs,
      isDisableSdsBulkCsvDialog,
      productFormItems,
      materialFormItems,
      casFormItems,
      showPrevSds,
      showNextSds,
      hasPrevSds,
      hasNextSds,
      showSdsDetail,
      doDeleteCompanySdses,
      doUpdateCompanySds,
      cancelEdit,
      resetPagenation,
      fetchSdses,
      doFetchCompanySds,
      doFetchPdf,
      exportCreateSimple,
      exportCreateSimpleV3,
      dialogShow,
      cancelExportCreateSimple,
      canExportCreateSimpleCount,
      canExportCreateSimpleCountV3,
      dynamicLists,
      assigneeUsers,
      tags,
      companyDepartments,
      fetchDynamicLists,
      editDynamicListResult,
      addDynamicListResult,
      deleteDynamicListResult,
      activeSds,
      isSdsFilterOpen,
      toggleSdsFilter,
      isChange,
      updateSelectedSdses,
      closeBulkCsvUploadForm,
      doDownloadSelectedSdsesCSV,
      doUploadSelectedSdsesCSV,
      csvFileValidate,
      hasPermission,
      P,
      handleClickProductName,
      handleClickManufacturerName,
      handleClickProductCode,
      handleClickTag,
      handleClickDepartment,
      handleClickStatus,
      exportOperations,
      sharedMessages,
      m,
      users,
    };
  },
});
